import { default as indexHincGRdIjpMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/agreements/index.vue?macro=true";
import { default as _91tab_939wjikLs8kGMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/[tab].vue?macro=true";
import { default as index5diXUvYNHnMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/[booking]/index.vue?macro=true";
import { default as indexsX8nOUB9mpMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/index.vue?macro=true";
import { default as indexqffeJtupcMMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/index.vue?macro=true";
import { default as indexkiVreqRP99Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/index.vue?macro=true";
import { default as blockedNaAMhYh6ZEMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/blocked.vue?macro=true";
import { default as indexEWc33owfsjMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/index.vue?macro=true";
import { default as indexXCNuDpHjiNMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/reset/index.vue?macro=true";
import { default as _91id_931ZhWEGybjJMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/[id].vue?macro=true";
import { default as indexCkima9EfqJMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/index.vue?macro=true";
import { default as _91id_930nOuRGz8JkMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/[id].vue?macro=true";
import { default as dealsrAJYnjWhpHMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/deals.vue?macro=true";
import { default as indexajcw8lKuFHMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/index.vue?macro=true";
import { default as indexvYvyJiv9kEMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payments/index.vue?macro=true";
import { default as _91id_93spNPCejyJdMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/[id].vue?macro=true";
import { default as enable_45disablemCN34326NnMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/enable-disable.vue?macro=true";
import { default as indexRTCAMRg8S4Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/index.vue?macro=true";
import { default as index6GxSsV2X1AMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[weekstart]/[invoiceId]/index.vue?macro=true";
import { default as indexz4V8FHLVlnMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[weekstart]/index.vue?macro=true";
import { default as index1E3v3ORhFZMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/index.vue?macro=true";
import { default as indexhcY2DHGDhPMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/index.vue?macro=true";
import { default as reinstatev6lOetxnCAMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/reinstate.vue?macro=true";
import { default as indexWjFI9wxMTzMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/verify/index.vue?macro=true";
import { default as indexZkg2Oediy5Meta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/reset-password/index.vue?macro=true";
import { default as _91id_93OZ6jhvQF9eMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/[id].vue?macro=true";
import { default as index8TylQKMgniMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/index.vue?macro=true";
import { default as newcVSW6RbYxhMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/new.vue?macro=true";
import { default as uploadZ2zlYRrN6ZMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/document/upload.vue?macro=true";
import { default as indexpGwVMYPePRMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/index.vue?macro=true";
import { default as index8dy3E0SAdkMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/index.vue?macro=true";
import { default as update42yRb49InCMeta } from "/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/update.vue?macro=true";
export default [
  {
    name: "agreements",
    path: "/agreements",
    meta: indexHincGRdIjpMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/agreements/index.vue")
  },
  {
    name: "bookings-earnings-date-tab",
    path: "/bookings-earnings/:date()/:tab()",
    meta: _91tab_939wjikLs8kGMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/[tab].vue")
  },
  {
    name: "bookings-earnings-date-bookings-day-booking",
    path: "/bookings-earnings/:date()/bookings/:day()/:booking()",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/[booking]/index.vue")
  },
  {
    name: "bookings-earnings-date-bookings-day",
    path: "/bookings-earnings/:date()/bookings/:day()",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/[date]/bookings/[day]/index.vue")
  },
  {
    name: "bookings-earnings",
    path: "/bookings-earnings",
    meta: indexqffeJtupcMMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/bookings-earnings/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexkiVreqRP99Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/index.vue")
  },
  {
    name: "login-blocked",
    path: "/login/blocked",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/blocked.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexEWc33owfsjMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/index.vue")
  },
  {
    name: "login-reset",
    path: "/login/reset",
    meta: indexXCNuDpHjiNMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/login/reset/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: _91id_931ZhWEGybjJMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexCkima9EfqJMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/news/index.vue")
  },
  {
    name: "partner-plus*****-id",
    path: "/partner-plus*****/:id()",
    meta: _91id_930nOuRGz8JkMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/[id].vue")
  },
  {
    name: "partner-plus*****-deals",
    path: "/partner-plus*****/deals",
    meta: dealsrAJYnjWhpHMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/deals.vue")
  },
  {
    name: "partner-plus*****",
    path: "/partner-plus*****",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/partner-plus*****/index.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexvYvyJiv9kEMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payments/index.vue")
  },
  {
    name: "payouts-id",
    path: "/payouts/:id()",
    meta: _91id_93spNPCejyJdMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/[id].vue")
  },
  {
    name: "payouts-enable-disable",
    path: "/payouts/enable-disable",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/enable-disable.vue")
  },
  {
    name: "payouts",
    path: "/payouts",
    meta: indexRTCAMRg8S4Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/payouts/index.vue")
  },
  {
    name: "plans-invoices-weekstart-invoiceId",
    path: "/plans-invoices/:weekstart()/:invoiceId()",
    meta: index6GxSsV2X1AMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[weekstart]/[invoiceId]/index.vue")
  },
  {
    name: "plans-invoices-weekstart",
    path: "/plans-invoices/:weekstart()",
    meta: indexz4V8FHLVlnMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/[weekstart]/index.vue")
  },
  {
    name: "plans-invoices",
    path: "/plans-invoices",
    meta: index1E3v3ORhFZMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/plans-invoices/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexhcY2DHGDhPMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/index.vue")
  },
  {
    name: "register-reinstate",
    path: "/register/reinstate",
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/reinstate.vue")
  },
  {
    name: "register-verify",
    path: "/register/verify",
    meta: indexWjFI9wxMTzMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/register/verify/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexZkg2Oediy5Meta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/reset-password/index.vue")
  },
  {
    name: "tickets-support-id",
    path: "/tickets-support/:id()",
    meta: _91id_93OZ6jhvQF9eMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/[id].vue")
  },
  {
    name: "tickets-support",
    path: "/tickets-support",
    meta: index8TylQKMgniMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/index.vue")
  },
  {
    name: "tickets-support-new",
    path: "/tickets-support/new",
    meta: newcVSW6RbYxhMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/tickets-support/new.vue")
  },
  {
    name: "vehicle-documents-document-upload",
    path: "/vehicle-documents/document/upload",
    meta: uploadZ2zlYRrN6ZMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/document/upload.vue")
  },
  {
    name: "vehicle-documents",
    path: "/vehicle-documents",
    meta: indexpGwVMYPePRMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/index.vue")
  },
  {
    name: "vehicle-documents-vehicle",
    path: "/vehicle-documents/vehicle",
    meta: index8dy3E0SAdkMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/index.vue")
  },
  {
    name: "vehicle-documents-vehicle-update",
    path: "/vehicle-documents/vehicle/update",
    meta: update42yRb49InCMeta || {},
    component: () => import("/home/runner/work/driver-portal-frontend/driver-portal-frontend/pages/vehicle-documents/vehicle/update.vue")
  }
]